<!-- This example requires Tailwind CSS v2.0+ -->
<template class="bg-ccream">
  <div data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1500">
    <div
      class="
        bg-ccream
        min-h-full
        px-4
        py-60
        h-50
        sm:px-6 sm:py-24
        md:grid md:place-items-center
        lg:px-8
      "
    >
      <div class="max-w-max mx-auto">
        <main class="sm:flex mt-30">
          <p class="text-4xl font-extrabold text-cgold sm:text-5xl mt-50">
            404
          </p>
          <div class="sm:ml-6">
            <div class="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1
                class="
                  text-4xl
                  font-extrabold
                  text-gray-900
                  tracking-tight
                  sm:text-5xl
                "
              >
                Page not found
              </h1>
              <p class="mt-1 text-base text-gray-500">
                Please check the URL in the address bar and try again.
              </p>
            </div>
            <div
              class="
                mt-10
                flex
                space-x-3
                sm:border-l sm:border-transparent sm:pl-6
              "
            >
              <router-link
                to="/"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-transparent
                  text-sm
                  font-medium
                  rounded-md
                  shadow-sm
                  text-white
                  bg-cgold
                  hover:bg-cgreen
                "
              >
                Go back home
              </router-link>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
